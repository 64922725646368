<template>
  <div>
    <div class="win-box">
      <p @click="close" class="button-icon"></p>
    </div>
    <img @click="close" class="close-icon" src="@/assets/win/close.png" />
  </div>
</template>

<script type="text/javascript">

export default {
  data() {
    return {
      
    }
  },
  computed:{
    
  },
  created(){
    
  },
  mounted(){
    
  },
  methods:{
    //关闭弹窗
    close(){
      this.$emit("close")
    }
  }
}
</script>

<style scoped>
  .win-box{
    width: 5.79rem;
    height: 4.75rem;
    background-image: url("~@/assets/win/viewTip.png");
    background-size: contain;
    padding-top: 2.9rem;
    box-sizing: border-box;
  }
  .close-icon{
    margin: 0.46rem auto 0;
    width: 0.65rem;
    height: 0.65rem;
  }
  .button-icon{
    width: 3.75rem;
    height: 0.95rem;
    margin: 0 auto;
  }
</style>
