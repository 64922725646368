<template>
  <div>
    <div class="win-box">
      <p class="prize-name">{{detail.name}}</p>
      <img class="prize-icon" :src="detail.picUrl" />
    </div>
    <div class="button-box flex align-items jc-center">
      <img @click="close" class="confirm-icon" src="@/assets/win/confirm.png" />
      <img @click="close" class="again-icon" src="@/assets/win/again.png" />
    </div>
    <img @click="close" class="close-icon" src="@/assets/win/close.png" />
  </div>
</template>

<script type="text/javascript">
export default {
  props:['detail'],
  data() {
    return {
      
    }
  },
  computed:{
    
  },
  created(){
    
  },
  mounted(){
    
  },
  methods:{
    //关闭弹窗
    close(){
      this.$emit("close")
    }
  }
}
</script>

<style scoped>
  .win-box{
    width: 7.46rem;
    height: 9.06rem;
    background-image: url("~@/assets/win/ticketTip.png");
    background-size: contain;
    padding-top: 3.68rem;
    box-sizing: border-box;
  }
  .close-icon{
    margin: 0.46rem auto 0;
    width: 0.65rem;
    height: 0.65rem;
  }
  .prize-name{
    font-size: 0.26rem;
    font-family: AlibabaPuHuiTiM;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.26rem;
    text-align: center;
  }
  .prize-icon{
    width: 1.97rem;
    height: 1.97rem;
    background-color: #FFFFFF;
    border: 0.06rem solid #FFFFFF;
    border-radius: 0.1rem;
    object-fit: cover;
    margin: 0.27rem auto;
  }
  .button-box{
    margin-top: -0.5rem;
  }
  .confirm-icon{
    width: 2.21rem;
    height: 0.78rem;
    margin-right: 0.22rem;
  }
  .again-icon{
    width: 2.51rem;
    height: 0.88rem;
  }
</style>
