<template>
  <div>
    <img class="page-bg" src="@/assets/lottery/bg.jpg" />
    <div class="page-container">
      <div class="title-box">
        <img class="title-icon" src="@/assets/record/title.png" />
      </div>
      <div class="list-box">
        <div v-for="item in myLuckList" :key="item.id">
          <div v-if="item.status==1" class="item-box flex align-items">
            <img class="item-img" :src="item.goodsPicUrl" />
            <div class="item-details">
              <p>抽中了</p>
              <p style="font-size:0.31rem;line-height:0.31rem;margin:0.09rem 0 0.15rem;">{{item.goodsName}}</p>
              <p style="color:#DCEBFF;">{{item.createTime}}</p>
            </div>
            <img @click="showPopup(item)" class="item-button" :src="require(`@/assets/record/${item.buttonType}.png`)" />
          </div>
        </div>
      </div>
      <img @click="pageBack" class="back-icon" src="@/assets/record/back.png" />
    </div>
    <Logo></Logo>
    <Popup ref="popup" v-slot="{ close }">
      <component @close="close" :refresh="getMyLuckList" :is="popupName" :detail="winDetail" :modify="winModify"></component>
    </Popup>
  </div>
</template>

<script type="text/javascript">
//组件
import Logo from "@/components/Logo.vue"
import Popup from "@/components/Popup.vue"
import EntityTip from "@/views/Lottery/components/EntityTip.vue"
import IntegralTip from "@/views/Lottery/components/IntegralTip.vue"
import TicketTip from "@/views/Lottery/components/TicketTip.vue"
import viewTip from "@/views/Record/components/viewTip.vue"

//api
import { myLuckList } from "@/api";

//store
import {mapState} from 'vuex'

//util
import {DateAdd,formatTime} from '@/utils/util'

export default {
  name: "Record",
  data() {
    return {
      myLuckList:[],//我的中奖列表
      popupName:"",//要显示的弹窗的名称
      winDetail:null,//弹窗内容
      winModify:true,//是否可修改弹窗内容
    }
  },
  computed:{
    ...mapState(['userInfo'])
  },
  components: {Logo,Popup,EntityTip,IntegralTip,TicketTip,viewTip},
  created(){
    this.getMyLuckList()
  },
  mounted(){
    
  },
  methods:{
    getMyLuckList(){//获取我的中奖记录
      let {token,uid,nickname} = this.userInfo
      let params = {token,uid,nickname}
      myLuckList(params).then(res=>{
        res.forEach(item=>{
          let {goodsType,addrCreateTime,addrUpdateCount} = item
          let buttonType = "look"
          if(goodsType==1){
            if(!addrCreateTime){
              buttonType = "modify"
            }else{
              let lastTime = formatTime(DateAdd('h',24,new Date(addrCreateTime)))
              if(Date.parse(lastTime)>Date.parse(new Date())&&addrUpdateCount<1){
                buttonType = "modify"
              }else{
                buttonType = "filled"
              }
            }
          }
          item.buttonType = buttonType
        })
        this.myLuckList = res
      })
    },
    showPopup(data){//显示弹窗
      let {goodsType,buttonType,goodsPicUrl,goodsName} = data
      if(goodsType==1){//实物
        this.popupName = "EntityTip"
        if(buttonType=='filled'){
          this.winModify = false
        }
      }else if(goodsType==2){//卡券
        this.popupName = "viewTip"
        data.picUrl = goodsPicUrl
        data.name = goodsName
      }else if(goodsType==3){//积分
        this.popupName = "viewTip"
        data.picUrl = goodsPicUrl
        data.name = goodsName
      }else{
        this.popupName = ""
      }
      this.winDetail = data
      this.$refs.popup.open()
    },
    pageBack(){//页面返回
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  .page-container,.page-bg{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 7.5rem;
    height: 15rem;
  }
  .page-container{
    height: 100%;
  }
  .title-box{
    padding-top: 1.18rem;
    width: 3.5rem;
    height: 0.89rem;
    margin: 0 auto;
  }
  .title-icon{
    width: 100%;
    height: 100%;
  }
  .list-box{
    margin-top: 0.5rem;
    height: calc(100% - 0.89rem - 1.18rem - 0.5rem - 1.36rem);
    box-sizing: border-box;
    overflow: auto;
  }
  .list-box>div{
    margin-top: 0.4rem;
  }
  .list-box>div:first-of-type{
    margin-top: 0;
  }
  .item-box{
    width: 6.96rem;
    height: 1.55rem;
    background-image: linear-gradient( to right, #6eadf6,#b1a8ef);
    box-shadow: 0px 0.1rem 0.4rem 0px rgba(80,149,227,0.24);
    border-radius: 0.38rem;
    margin: 0 auto;
    padding: 0.3rem;
    box-sizing: border-box;
  }
  .item-img{
    width: 1.02rem;
    height: 1.02rem;
    border: 0.02rem solid #FFFFFF;
    border-radius: 0.1rem;
    background-color: #FFFFFF;
    object-fit: cover;
    box-sizing: border-box;
  }
  .item-details{
    width: calc(100% - 1.02rem - 1.95rem);
    padding: 0 0.15rem 0 0.2rem;
    box-sizing: border-box;
    font-size: 0.26rem;
    font-family: AlibabaPuHuiTiM;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.26rem;
  }
  .item-button{
    width: 1.95rem;
    height: 0.8rem;
  }
  .back-icon{
    width: 3.76rem;
    height: 0.96rem;
    margin: 0.2rem auto 0;
  }
</style>
