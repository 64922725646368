<template>
  <div>
    <div class="win-box">
      <p class="prize-name">{{detailData.name}}</p>
      <img class="prize-icon" :src="detailData.picUrl" />
      <div class="input-group">
        <input :disabled="!modify" class="input-box" type="text" v-model="form.name" placeholder="点击输入">
        <input :disabled="!modify" class="input-box" type="number" v-model="form.mobile" maxlength="11" placeholder="点击输入">
        <textarea :disabled="!modify" class="textarea-box" v-model="form.addr" placeholder="xx省xx市xxxxxxxx"></textarea>
      </div>
    </div>
    <div class="button-box flex align-items jc-center">
      <img @click="addrUpdate" class="confirm-icon" src="@/assets/win/confirm.png" />
      <img @click="addrUpdate" class="again-icon" src="@/assets/win/again.png" />
    </div>
    <img @click="close" class="close-icon" src="@/assets/win/close.png" />
  </div>
</template>

<script type="text/javascript">
//api
import { addrUpdate } from "@/api";

//store
import {mapState} from 'vuex'

export default {
  props:{
    detail:{
      type: Object
    },
    modify:{
      type:Boolean,
      default:true
    },
    refresh:{
      type: Function,
      default:()=>{}
    }
  },
  data() {
    return {
      detailData:{},
      form:{}
    }
  },
  computed:{
    ...mapState(['userInfo']),
  },
  created(){
    
  },
  mounted(){
    let { recordId } = this.detail
    if(!recordId){
      let { id:recordId,goodsName,goodsPicUrl,name,mobile,addr } = this.detail
      this.form = {name,mobile,addr}
      this.detailData = {recordId,name:goodsName,picUrl:goodsPicUrl}
    }else{
      this.detailData = this.detail
    }
  },
  methods:{
    addrUpdate(){//修改发货地址
      if(!this.modify){
        this.close()
        return
      }
      let {uid} = this.userInfo
      let { recordId:id } = this.detailData
      let {name,mobile,addr} = this.form
      if(!name){
        this.$layer.msg("请输入姓名");
        return
      }
      if(!mobile){
        this.$layer.msg("请输入手机号");
        return
      }
      if(mobile.length!=11){
        this.$layer.msg("请输入正确的手机号");
        return
      }
      if(!addr){
        this.$layer.msg("请输入地址");
        return
      }
      let params = {uid,id,name,mobile,addr}
      addrUpdate(params).then(res=>{
        this.refresh()
        this.close()
      })
    },
    //关闭弹窗
    close(){
      this.$emit("close")
    }
  }
}
</script>

<style scoped>
  .win-box{
    width: 7.43rem;
    height: 11.16rem;
    background-image: url("~@/assets/win/entityTip.png");
    background-size: contain;
    padding-top: 3.7rem;
    box-sizing: border-box;
  }
  .close-icon{
    margin: 0.46rem auto 0;
    width: 0.65rem;
    height: 0.65rem;
  }
  .prize-name{
    font-size: 0.27rem;
    font-family: AlibabaPuHuiTiM;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.27rem;
    text-align: center;
  }
  .prize-icon{
    width: 1.97rem;
    height: 1.97rem;
    background-color: #FFFFFF;
    border: 0.06rem solid #FFFFFF;
    border-radius: 0.1rem;
    object-fit: cover;
    margin: 0.24rem auto 0.92rem;
  }
  .input-group>*{
    margin: 0.18rem 0 0 2.5rem;
  }
  .input-group>.input-box:first-of-type{
    margin-top: 0;
  }
  .input-box{
    width: 3.13rem;
    height: 0.53rem;
    border-radius: 0.05rem;
    padding: 0 0.15rem;
    font-size: 0.22rem;
    font-family: AlibabaPuHuiTiM;
    font-weight: 400;
    color: #FEFFFF;
    line-height: 0.53rem;
    box-sizing: border-box;
    display: block;
  }
  .textarea-box{
    width: 3.13rem;
    height: 1.06rem;
    border-radius: 0.05rem;
    padding: 0.15rem;
    font-size: 0.22rem;
    font-family: AlibabaPuHuiTiM;
    font-weight: 400;
    color: #FEFFFF;
    line-height: 0.32rem;
    box-sizing: border-box;
    display: block;
  }
  .button-box{
    margin-top: -0.5rem;
  }
  .confirm-icon{
    width: 2.21rem;
    height: 0.78rem;
    margin-right: 0.22rem;
  }
  .again-icon{
    width: 2.51rem;
    height: 0.88rem;
  }
</style>
