<template>
  <div v-if="popupShow" class="popup-box flex align-items jc-center">
    <slot :close="close"></slot>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      popupShow:false,//是否显示弹窗
    }
  },
  computed:{
    
  },
  components: {},
  created(){
    
  },
  mounted(){
    
  },
  methods:{
    //打开弹窗
    open(){
      this.popupShow = true
    },
    //关闭弹窗
    close(){
      this.popupShow = false
    }
  }
}
</script>

<style scoped>
  .popup-box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0.2rem);
  }
</style>
